import { useFetchExerciseQuery } from "../../store";
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SetWorkOutInput from "../forms/inputs/SetWorkoutInput";
import SetWorkOutInputGlobal from "../forms/inputs/SetWorkoutInputGlobal";
import { nanoid } from "@reduxjs/toolkit";
import { useState } from "react";
import ChooseExerciseModal from "../modals/ChooseExerciseModal";
import NoteModal from "../modals/NoteModal";
import { useDispatch } from "react-redux";
import { editExerciseNote, editSet } from "../../store";


function ExerciseInWorkoutCard ({exercise, onRemoveExercise, onAddSet, onRemoveSet, onOrderUpExercise, onOrderDownExercise, exercisesLenght}){
  const dispatch = useDispatch();
  const { data, error, isLoading} = useFetchExerciseQuery(exercise.id);
  const [openModal, setOpenModal] = useState(false);
  const [modalExerciseKey, setModalExerciseKey] = useState(false);
  const [openModalExerciseNote, setOpenModalExerciseNote] = useState(false);
  const [modalExerciseNoteData, setModalExerciseNoteData] = useState('');
  const [openModalSetNote, setOpenModalSetNote] = useState(false);
  const [modalSetNoteData, setModalSetNoteData] = useState('');
  const [modalSetData, setModalSetData] = useState('');
  
  const onClickSwitchExercise = (event, exerciseKey) =>{
    event.preventDefault();
    setModalExerciseKey(exerciseKey);
    setOpenModal(true);
  };

  const onClickAddNotes = (event, key, note) =>{
    event.preventDefault();
    setModalExerciseNoteData((note === null || note === undefined)? '': note);
    setModalExerciseKey(key);
    setOpenModalExerciseNote(true);
  };

  const onClickAddNotesSet = (event, exerciseKey, set) =>{
    event.preventDefault();
    setModalSetNoteData((set.note === null || set.note === undefined)? '': set.note);
    setModalExerciseKey(exerciseKey);
    setModalSetData(set);
    setOpenModalSetNote(true);
  };
  
  const onSaveNote = (exerciseKey, note) =>{
    dispatch(editExerciseNote({exerciseKey, note}));
  }

  const onSaveSetNote = (exerciseKey, note) =>{
    let newSet = {...modalSetData,note:note};
    dispatch(editSet({exerciseKey, newSet}));
  }
  let renderedExercise;
  if (isLoading){
    renderedExercise = <div> Loading</div>
  } else if (error){
    renderedExercise = <div> Errors</div>
  } else {
    let exerciseFromQuery = data[0];
    let orderUpButton = <>
      <button
        type="button"
        onClick={() => {onOrderUpExercise(exercise.key)}}
        className="rounded-full mx-1 bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </>;
    let orderDownButton = <>
      <button
        type="button"
        onClick={() => {onOrderDownExercise(exercise.key)}}
        className="rounded-full mx-1 bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </>;
    if(exercisesLenght === 1 || exercise.order === 1 ){
      orderUpButton = <></>;
    }
    if(exercise.order >= exercisesLenght ){
      orderDownButton = <></>;
    }
    renderedExercise = <>
      <li key={exercise.key} className="overflow-hidden rounded-xl border border-gray-200">
        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          {/*<img
            src="{exercise.imageUrl}"
            alt={exerciseFromQuery.name}
            className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
          />
          */}
          <div className="text-sm font-medium leading-6 text-gray-900">
            {exerciseFromQuery.name} {exercise.order}
            <button
                type="button"
                onClick={(event) => {onClickSwitchExercise(event,exercise.key)}}
                className="rounded bg-white px-2 py-1 text-sm ml-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Switch exercise
            </button>
            <button
                type="button"
                onClick={(event) => {onClickAddNotes(event, exercise.key, exercise.note)}}
                className="rounded bg-white px-2 py-1 text-sm ml-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Add notes
            </button>
          </div>
          <div className="relative ml-auto">
            <button
                type="button"
                onClick={() => {onAddSet(exercise.key)}}
                className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Add set
            </button>
            {orderUpButton}
            {orderDownButton}
            
            <button
              type="button"
              onClick={() => {onRemoveExercise(exercise.key)}}
              className="rounded-full mx-1 bg-red-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <MinusIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <table className="min-w-full table-fixed divide-y divide-gray-300">
            <thead className="">
              <tr className="">
                <th className="w-12 font-bold text-gray-900">Set #</th>
                <th className="w-12 font-bold text-gray-900">Kg</th>
                <th className="w-12 font-bold text-gray-900">Time</th>
                <th className="w-12 font-bold text-gray-900">Reps</th>
                <th className="w-12 font-bold text-gray-900">RPE</th>
                <th className="w-12 font-bold text-gray-900">RIR</th>
                <th className="w-12 font-bold text-gray-900">TUT</th>
                <th className="w-12 font-bold text-gray-900">%RM</th>
                <th className="w-12 font-bold text-gray-900">Rest</th>
                <th className="w-12 font-bold text-gray-900"> </th>
                <th className="w-12 font-bold text-gray-900"> </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr key={nanoid()}>
              <td className="font-medium text-gray-500">All sets: </td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='kg'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='time'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='reps'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='rpe'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='rir'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='tut'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='percentage_rm'/></td>
                <td className="text-gray-500"><SetWorkOutInputGlobal exerciseKey={exercise.key} attribute='rest'/></td>
              </tr>
          {exercise.sets.map((set) => (
              <tr key={nanoid()}>
                <td className="font-medium text-gray-500">Set {set.index} </td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.kg} attribute='kg'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.time} attribute='time'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.reps} attribute='reps'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.rpe} attribute='rpe'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.rir} attribute='rir'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.tut} attribute='tut'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.percentage_rm} attribute='percentage_rm'/></td>
                <td className="text-gray-500"><SetWorkOutInput exerciseKey={exercise.key} set={set} value={set.rest} attribute='rest'/></td>
                <td className="text-gray-500">
                  <button
                    type="button"
                    onClick={(event) => {onClickAddNotesSet(event, exercise.key, set)}}
                    className="rounded bg-white px-2 py-1 text-sm ml-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Add notes
                  </button>
                </td>
                <td className="text-gray-500"><button
                    type="button"
                    onClick={() => {onRemoveSet(exercise.key, set.id)}}
                    className="rounded-full mx-1 bg-red-white p-1 text-black shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  >
                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </td>
              </tr>
          ))}
            </tbody>
          </table>
        </div>
      </li>
    </>;
  }
  return(
    <>
    {renderedExercise}
    <ChooseExerciseModal  open={openModal} setOpen={setOpenModal} exerciseKey={modalExerciseKey}/>
    
    <NoteModal open={openModalExerciseNote} itemKey={modalExerciseKey} setOpen={setOpenModalExerciseNote} initialNote={modalExerciseNoteData} onSave={onSaveNote}/>
    <NoteModal open={openModalSetNote} itemKey={modalExerciseKey} setOpen={setOpenModalSetNote} initialNote={modalSetNoteData} onSave={onSaveSetNote}/>
    </>
  );
}

export default ExerciseInWorkoutCard;