import { useCreateFmsMutation } from "../../store";
import StandardForm from "../forms/StandardForm";

export default function FMSForm({athlete}){
  const [createFMS] = useCreateFmsMutation();
  const handleCreate = (e)=>{
    e.preventDefault();
    const submitData = config.reduce((o, input) => ({ ...o, [input.id]: e.target[input.id].value}), {});
    const createData = {...submitData, athlete:athlete.id};
    createFMS(createData);
  }
  const meta = {
    object: "Functional Movement Screen Form",
    action: "Register a new FMS Record",
    description: "Create a new FMS Record.",
  };
  let config = [
    {
      id:'shoulder',
      label: 'Shoulders',
      type: 'shortText',
      size: 2
    },
    {
      id:'hip',
      label: 'Hips',
      type: 'shortText',
      size: 2
    },
    {
      id:'front_stability',
      label: 'Front Stability',
      type: 'shortText',
      size: 2
    },
    {
      id:'rotational_stability',
      label: 'Rotational Stability',
      type: 'shortText',
      size: 2
    },
    {
      id:'leg_raise',
      label: 'Leg Raise',
      type: 'shortText',
      size: 2
    },
    {
      id:'inline_lunge',
      label: 'In Line Lunge',
      type: 'shortText',
      size: 2
    },
    {
      id:'squat',
      label: 'Squat',
      type: 'shortText',
      size: 2
    },
    {
      id:'ankle',
      label: 'Ankle Dorsiflexion',
      type: 'shortText',
      size: 2
    },
  ];
  return(
    <StandardForm breadcrumb={false} meta={meta} config={config} onSubmit={handleCreate}/>
  );
}