import SideBar from "../../components/navigation/SideBar";
import BasicActions from "../../components/headings/BasicActions";
import TableCard from "../../components/tables/TableCard";
import { NavLink, useNavigate } from "react-router-dom";
import { useDeleteExerciseMutation, useFetchExercisesQuery } from "../../store";
import { useCoach } from "../../useCoach";
import ExercisesTable from "../../components/tables/ExercisesTable";

function Exercises(){
  const { coach } = useCoach();
  const navigate = useNavigate();
  const [deleteExercise, results] = useDeleteExerciseMutation();
  const handleDelete = (e,exerciseId)=>{
    e.preventDefault();
    deleteExercise(exerciseId);
  }

  const handleNew = (event)=>{
    event.preventDefault();
    navigate("/exercise/create");
  }

  const actions = [
    {label: 'New', onClick:handleNew, class: 'primary'},
  ]

  const keyFn = (exercise) => {
    return exercise.id;
  };

  const { data, error, isLoading} = useFetchExercisesQuery(coach.id);
  let table;
  if (isLoading){
    table = <div> Loading</div>
  } else if (error){
    table = <div> Errors</div>
  } else {
    table = <ExercisesTable data={data} handleDelete={handleDelete} />
  }
  return (
    <>
    <div>
      <SideBar/>
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <BasicActions object="Exercises" actions={actions}/>
          {table}
        </div>
      </main>
    </div>
    </>
  );
}

export default Exercises;