import { useParams } from "react-router-dom";
import AssignProgramModal from "../../../components/modals/AssignProgramModal";
import { useFetchProgramAthleteQuery, useUpdateProgramAthleteMutation } from "../../../store";
import { useState, useRef } from "react";
import TableCard from "../../../components/tables/TableCard";
import ProgramProgress from "./ProgramProgress";

function ProgramManager() {
  let { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const { data, error, isLoading } = useFetchProgramAthleteQuery(id);
  const [updateProgramAthlete] = useUpdateProgramAthleteMutation();
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const progressRef = useRef(null);
  const tableRef = useRef(null);

  const handleOpenModal = (event) =>{
    event.preventDefault();
    setOpenModal(true);
  };
  const handleUnassign = (event) => {
    event.preventDefault();
  };

  const handleDeactivate = (event, programAssigned) => {
    event.preventDefault();
    updateProgramAthlete({ id: programAssigned.id, submitData: { active: false } });
  };

  const handleActivate = (event, programAssigned) => {
    event.preventDefault();
    updateProgramAthlete({ id: programAssigned.id, submitData: { active: true } });
  };

  const handleComplete = (event, programAssigned) => {
    event.preventDefault();
    updateProgramAthlete({ id: programAssigned.id, submitData: { completed: true } });
  };

  const handleIncomplete = (event, programAssigned) => {
    event.preventDefault();
    updateProgramAthlete({ id: programAssigned.id, submitData: { completed: false } });
  };

  const handleSeeProgress = (event, programId) => {
    event.preventDefault();
    if (selectedProgramId === programId) {
      setSelectedProgramId(null);
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      setSelectedProgramId(programId);
      setTimeout(() => {
        progressRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  };

  const config = [
    {
      key: 'Id',
      label: 'Id',
      render: (program) => program.id,
      rowClassname: 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6',
      columnClassname: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      key: 'Name',
      label: 'Name',
      render: (programAssigned) => programAssigned.program.name,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Type',
      label: 'Type',
      render: (programAssigned) => programAssigned.program.type,
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Status',
      label: 'Status',
      render: (programAssigned) => {
        return programAssigned.active ? "Active" : "Not active";
      },
      rowClassname: 'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'Unassign',
      label: '',
      render: (program) => (
        <button
          onClick={() => handleUnassign(program.id)}
          className="hidden rounded-md bg-red-400 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:block"
        >
          Unassign
        </button>
      ),
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'ActivateDeactivate',
      label: '',
      render: (program) => program.active ? (
        <button
          onClick={(event) => handleDeactivate(event, program)}
          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
        >
          Deactivate
        </button>
      ) : (
        <button
          onClick={(event) => handleActivate(event, program)}
          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
        >
          Activate
        </button>
      ),
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'CompleteIncomplete',
      label: '',
      render: (program) => program.completed ? (
        <button
          onClick={(event) => handleIncomplete(event, program)}
          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
        >
          Mark Incomplete
        </button>
      ) : (
        <button
          onClick={(event) => handleComplete(event, program)}
          className="hidden rounded-md bg-green-200 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-500 sm:block"
        >
          Mark Complete
        </button>
      ),
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
    {
      key: 'SeeProgress',
      label: '',
      render: (program) => (
        <button
          onClick={(event) => handleSeeProgress(event, program.id)}
          className="hidden rounded-md bg-blue-200 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-blue-500 sm:block"
        >
          {selectedProgramId === program.id ? "Hide Progress" : "See Progress"}
        </button>
      ),
      rowClassname: 'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6',
      columnClassname: 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900',
    },
  ];

  const keyFn = (program) => {
    return program.id;
  };

  let programsAssigned;

  if (isLoading) {
    programsAssigned = <div>Loading</div>;
  } else if (error) {
    programsAssigned = <div>Errors</div>;
  } else {
    programsAssigned = <TableCard data={data} config={config} keyFn={keyFn} />;
  }

  const selectedProgram = data?.find(program => program.id === selectedProgramId);

  return (
    <form>
      <div className="space-y-12">
        <div ref={tableRef} className="border-b border-gray-900/10 pb-12">
          <button
            onClick={(event) => handleOpenModal(event)}
            className="mt-8 ml-8 mr-7 rounded-md bg-indigo-600 px-4 py-2 text-white"
          >
            Assign program to athlete
          </button>
          <AssignProgramModal open={openModal} setOpen={setOpenModal} />
          Only one should be active.
          {programsAssigned}
        </div>
        {selectedProgram && (
          <div ref={progressRef}>
            <ProgramProgress program={selectedProgram} />
          </div>
        )}
      </div>
    </form>
  );
}

export default ProgramManager;
