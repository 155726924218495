import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editSet } from '../../../store';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function SetWorkOutInputGlobal({ exerciseKey, attribute }) {
  const dispatch = useDispatch();
  const sets = useSelector((state) =>
    state.exercisesInWorkout.data.find((exercise) => exercise.key === exerciseKey)?.sets || []
  );

  const handleChange = (e) => {
    let value = e.target.value;
    sets.forEach((set) => {
      let newSet = { ...set, [attribute]: value };
      dispatch(editSet({ exerciseKey, newSet }));
    });
  };

  return (
    <input
      key={`${exerciseKey}-Global-${attribute}`}
      id={`${exerciseKey}-Global-${attribute}`}
      onBlur={handleChange}
      type="text"
      className={classNames(attribute === 'tut' ? 'w-14' : 'w-12', 'sm:text-xs')}
    />
  );
}

export default SetWorkOutInputGlobal;
